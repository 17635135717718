import axios from 'axios'

export default {
    get: () => axios.get('products'),
    getByMobile: () => axios.get('products/mobile'),
    getSearching: (search) => axios.post('products/searching', {
        search: search
    }),
    getFiltered: (types, colors, materials, sizes, tags, search) => axios.post('products/filtered', {
        types: types,
        colors: colors,
        materials: materials,
        sizes: sizes,
        tags: tags,
        search: search
    }),
    shareFiltered: (types, colors, materials, sizes, tags, search) => axios.post('products/share-filtered', {
        types: types,
        colors: colors,
        materials: materials,
        sizes: sizes,
        tags: tags,
        search: search
    }),
    store: (data) => axios.post('products/store', data),
    update: (data) => axios.post('products/update', data),
    delete: (id) => axios.post('products/delete', id),
    updatePrice: (id, price) => axios.post('products/update-price', {id: id, price: price}),
}